import React, { useState, useEffect } from 'react'
import Select from 'react-select';

import { MappingTable } from './MappingTable';
import { ConfigureStyles } from './styles';
import Bidirectional from '../../../../../Assets/img/Directions/bidirectional.svg';
import Left from '../../../../../Assets/img/Directions/left.svg';
import Right from '../../../../../Assets/img/Directions/right.svg';
import { capitalLetter } from '../../../../../Utils/String';
import { styleTheme, styleThemeHS } from '../../../../../Styles/SelectTheme';
import { useGetMappingMutation } from '../../../../../Store/api/apps';
import { getObjectsQueryUrl } from '../../../../../Utils/QueryUrl';
import { ApiRequest } from '../../../../../Utils/ApiRequest';

const Limit = ({ account, generalApp, typeObject, setFiltersData, filtersData}) => {
  let objQuery = getObjectsQueryUrl(window.location.href);
  const [selectOptionsHS, setSelectOptionsHS] = useState([]);

  let [searchMapping, { isLoading: isLoadingMapping, isSuccess: isSuccessMapping, data: responseMapping }] = useGetMappingMutation();

  useEffect(() => {

    searchMapping({
      accountId: objQuery.accountId,
      portalId: objQuery.portalId,
      appId: objQuery.appId,
      object: typeObject
    })
    }, [])



    useEffect(() => {
      const getOptionsHS = async (load, filter) => {
        try {
          let body = {
            id_register: account.data.id,
            urlTokenRefresh: generalApp.urls.tokenRefresh,
            nameApp: generalApp.config?.app_name
          }
          let result = await ApiRequest.post(`/hubspot/getOptions/${load}`, body, "token");
          return {
            filterId: filter.id,
            options: result.data
          };
        } catch (error) {
          console.warn(error);
          return {
            filterId: filter.id,
            options: [] // o null, dependiendo de lo que prefieras en caso de error
          };
        }
      }
      
      if(isSuccessMapping){
        const fetchOptions = async () => {
          // Hacer una copia profunda de los filtros
          let filters = JSON.parse(JSON.stringify(responseMapping.data.filters));
          
          const filtersDataHS = [...responseMapping.data.filters.app_hs];
          // Crear un array de promesas para todas las llamadas a getOptionsHS
          const optionsPromises = filtersDataHS
            .filter(filter => filter.optionsLoad)
            .map(filter => getOptionsHS(filter.optionsLoad, filter));
          
          // Esperar a que todas las llamadas se completen
          const optionsResults = await Promise.all(optionsPromises);
          
          // Actualizar los filtros con las opciones obtenidas
          optionsResults.forEach(({filterId, options}) => {
            const find = filters.app_hs.find(el => el.id === filterId);
            if (find) {
              find.options = options;
            }
          });

          const filtersDataApp = [...responseMapping.data.filters.hs_app];
           // Crear un array de promesas para todas las llamadas a getOptionsHS
          const optionsPromises2 = filtersDataApp
            .filter(filter => filter.optionsLoad)
            .map(filter => getOptionsHS(filter.optionsLoad, filter));
          
          // Esperar a que todas las llamadas se completen
          const optionsResults2 = await Promise.all(optionsPromises2);
          
          // Actualizar los filtros con las opciones obtenidas
          optionsResults2.forEach(({filterId, options}) => {
            const find = filters.hs_app.find(el => el.id === filterId);
            if (find) {
              find.options = options;
            }
          });
          
          setFiltersData(filters);
        };
        
        fetchOptions();
        
      }
    }, [isSuccessMapping])

    const handleOption = (data, option, direction) => {
      let filters = JSON.parse(JSON.stringify(responseMapping.data.filters));
      let findFilter = filters[direction].find(el => el.id === data.id);
      findFilter.value = option.value;
      setFiltersData(filters)
    }
    const selectFilter = (option, data, direction) => {
      console.log(option, data, direction)
      let filters = JSON.parse(JSON.stringify(responseMapping.data.filters));
      let findFilter = filters[direction].find(el => el.id === data.id);
      console.log({findFilter})
      findFilter.value = option.value;
      findFilter.options = data.options;
      setFiltersData(filters)
    }
  return (
    <ConfigureStyles>
      <div className='sync-direction review'>
        <div>
          <div className='title-main'>
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48"><path fill="currentColor" fillRule="evenodd" d="M20 6h8v.734c.618 6.866 2.662 8.109 8.898 5.2l.69-.398l4 6.928l-.39.225c-5.904 4.1-5.965 6.509-.066 10.583l.457.264l-.02.034l-3.98 6.894l-.762-.44c-6.355-2.906-8.292-1.476-8.8 6L28 41.697V42h-8v-1.166c-.67-6.626-2.82-7.64-9.154-4.62l-.434.25l-4-6.928l1.035-.598c5.239-3.782 5.237-6.145-.103-9.935l-.932-.539l4-6.928l.223.129c6.47 3.097 8.7 2.166 9.365-4.463zm9 18a5 5 0 1 1-10 0a5 5 0 0 1 10 0m3 0a8 8 0 1 1-16 0a8 8 0 0 1 16 0m2 0c0 5.523-4.477 10-10 10s-10-4.477-10-10s4.477-10 10-10s10 4.477 10 10" clipRule="evenodd"/></svg> 
            <h2>Filter</h2>
          </div>
          {filtersData.isActive ? <p>All records are included in the synchronization by default. Choose criteria to limit which ones will be synchronized.</p>: <p>No filters to configure</p>}
        </div>

        <div className='section-sync'>

        {
          filtersData.isActive && filtersData.app_hs ? 
          <div className='card-sync'>
            <div className='header'>
              <div className="grid">
                  <div className='item-left'>
                    <div>
                        <div className='image-app'>
                        <img src={generalApp.logo} alt="app" />
                        </div>
                        <div className='title-app'>
                        <span>{capitalLetter(generalApp.config?.app_name)}</span>
                        </div>
                    </div>
                  </div>
                  <div className='item-center' >
                    <img src={Right} alt="edit" />
                  </div>
                  <div className='item-right'>
                  <div>
                        <div className='image-app'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 512 512"><path fill="#f97a5a" d="M267.4 211.6c-25.1 23.7-40.8 57.3-40.8 94.6c0 29.3 9.7 56.3 26 78L203.1 434c-4.4-1.6-9.1-2.5-14-2.5c-10.8 0-20.9 4.2-28.5 11.8s-11.8 17.8-11.8 28.6s4.2 20.9 11.8 28.5s17.8 11.6 28.5 11.6c10.8 0 20.9-3.9 28.6-11.6c7.6-7.6 11.8-17.8 11.8-28.5c0-4.2-.6-8.2-1.9-12.1l50-50.2c22 16.9 49.4 26.9 79.3 26.9c71.9 0 130-58.3 130-130.2c0-65.2-47.7-119.2-110.2-128.7V116c17.5-7.4 28.2-23.8 28.2-42.9c0-26.1-20.9-47.9-47-47.9S311.2 47 311.2 73.1c0 19.1 10.7 35.5 28.2 42.9v61.2c-15.2 2.1-29.6 6.7-42.7 13.6c-27.6-20.9-117.5-85.7-168.9-124.8c1.2-4.4 2-9 2-13.8C129.8 23.4 106.3 0 77.4 0C48.6 0 25.2 23.4 25.2 52.2c0 28.9 23.4 52.3 52.2 52.3c9.8 0 18.9-2.9 26.8-7.6zm89.5 163.6c-38.1 0-69-30.9-69-69s30.9-69 69-69s69 30.9 69 69s-30.9 69-69 69"/></svg>
                        </div>
                        <div className='title-app'>
                          <span>HubSpot</span>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className='body-card'>
              
              {filtersData.app_hs.map(el => {
                if(el.fieldType === "radio") {
                  return (<>
                    <h4>{el.title}</h4>
                    {el.options.map(option => {
                      return ( <div class="radio-wrapper">
                        <label htmlFor={option.value}>
                          <input id={option.value} type="radio" name={el.id} defaultChecked={el.value === option.value} onClick={() =>handleOption(el, option, "app_hs")}/>
                          <span class="label"></span>
                          {option.label}
                        </label>
                      </div>)
                    })}
                    {filtersData.app_hs.length !== 1 ? <hr />: <></>}
                  </>
                  )
                } else if(el.fieldType === "select") {
                  return (<>
                    <h4>{el.title}</h4>
                    <div class="form-control" style={{width: "400px"}}>
                      <div className="container-select">
                        {el.options.length ? <Select
                          options={[{label: "None", value: ""}, ...el.options]}
                          placeholder="Search..."
                          theme={(theme) => styleTheme(theme)}
                          value={el.options?.find(opt => opt.value === el.value) || null}
                          onChange={(e) => selectFilter(e, el, "app_hs")}
                        />: <>Cargando...</>}
                      </div>
                    </div>
                    {filtersData.app_hs.length !== 1 ? <hr />: <></>}
                  </>
                  )
                }
                
              })}
              </div>
            </div>
          : <></>
        }
        {
            filtersData.isActive && filtersData.hs_app ? <div className='card-sync'>
            <div className='header'>
              <div className="grid">
                  <div className='item-left'>
                    <div>
                        <div className='image-app'>
                        <img src={generalApp.logo} alt="app" />
                        </div>
                        <div className='title-app'>
                        <span>{capitalLetter(generalApp.config?.app_name)}</span>
                        </div>
                    </div>
                  </div>
                  <div className='item-center' >
                    <img src={Left} alt="edit" />
                  </div>
                  <div className='item-right'>
                  <div>
                        <div className='image-app'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 512 512"><path fill="#f97a5a" d="M267.4 211.6c-25.1 23.7-40.8 57.3-40.8 94.6c0 29.3 9.7 56.3 26 78L203.1 434c-4.4-1.6-9.1-2.5-14-2.5c-10.8 0-20.9 4.2-28.5 11.8s-11.8 17.8-11.8 28.6s4.2 20.9 11.8 28.5s17.8 11.6 28.5 11.6c10.8 0 20.9-3.9 28.6-11.6c7.6-7.6 11.8-17.8 11.8-28.5c0-4.2-.6-8.2-1.9-12.1l50-50.2c22 16.9 49.4 26.9 79.3 26.9c71.9 0 130-58.3 130-130.2c0-65.2-47.7-119.2-110.2-128.7V116c17.5-7.4 28.2-23.8 28.2-42.9c0-26.1-20.9-47.9-47-47.9S311.2 47 311.2 73.1c0 19.1 10.7 35.5 28.2 42.9v61.2c-15.2 2.1-29.6 6.7-42.7 13.6c-27.6-20.9-117.5-85.7-168.9-124.8c1.2-4.4 2-9 2-13.8C129.8 23.4 106.3 0 77.4 0C48.6 0 25.2 23.4 25.2 52.2c0 28.9 23.4 52.3 52.2 52.3c9.8 0 18.9-2.9 26.8-7.6zm89.5 163.6c-38.1 0-69-30.9-69-69s30.9-69 69-69s69 30.9 69 69s-30.9 69-69 69"/></svg>
                        </div>
                        <div className='title-app'>
                          <span>HubSpot</span>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className='body-card'>

              {filtersData.hs_app.map(el => {
                if(el.fieldType === "radio") {
                return (<>
                  <h4>{el.title}</h4>
                  {el.options.map(option => {
                    return (<div class="radio-wrapper">
                      <label htmlFor={option.value}>
                        <input id={option.value} type="radio" name={el.id} defaultChecked={el.value === option.value} onClick={() =>handleOption(el, option, "hs_app")}/>
                        <span class="label"></span>
                        {option.label}
                      </label>
                    </div>)
                  })}
                  {filtersData.hs_app.length !== 1 ? <hr />: <></>}
                </>
              )
                } else if(el.fieldType === "select") {
                  return (<>
                    <h4>{el.title}</h4>
                    <div class="form-control" style={{width: "400px"}}>
                      <div className="container-select">
                        {el.options.length ? <Select
                          options={[{label: "None", value: ""}, ...el.options]}
                          placeholder="Search..."
                          theme={(theme) => styleTheme(theme)}
                          value={el.options?.find(opt => opt.value === el.value) || null}
                          onChange={(e) => selectFilter(e, el, "hs_app")}
                        />: <>Cargando...</>}
                      </div>
                    </div>
                    {filtersData.app_hs.length !== 1 ? <hr />: <></>}
                  </>
                  )
                }
              })}
            </div>
          </div>: <></>
        }
        </div>
      </div>
    </ConfigureStyles>
  )
}

export default Limit;
