import axios from 'axios';

const tokenUrl = (queryToken) => {
  // Obtener la URL actual
  const url = window.location.href;

  // Crear un objeto URLSearchParams con los parámetros de la URL
  const queryParams = new URLSearchParams(url.split('?')[1]);

  // Convertir los parámetros en un objeto
  const paramsObj = {};
  queryParams.forEach((value, key) => {
      paramsObj[key] = value;
  });

  // If we have a token set in state, let's assume that we should be passing it.
  return `Bearer ${paramsObj[queryToken]}`
}
export const ApiRequest = {
  
  async publicPost(url, body, headers = {}) {
    try {
      let { data } = await axios.post(`${process.env.REACT_APP_API_URL}${url}`, body, {
        responseType: 'json',
        headers: {
          ...headers,
        },
      });
      if (data.ok === false)
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            status: 'Data Failure',
            statusText: data.message || `Error API: ${process.env.REACT_APP_API_URL}${url}`,
          },
        };
      return data;
    } catch (error) {
      console.log(error);
      // eslint-disable-next-line no-throw-literal
      throw {
        status: error.response.status,
        statusText: error.response.data?.message || error.response.statusText || `Error Validation API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async get(url) {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user')}`,
        },
      });
      if (data.ok === false)
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: error.response.status,
        statusText: error.response.data?.message || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async post(url, body, queryToken = null) {
    try {
      let { data } = await axios.post(`${process.env.REACT_APP_API_URL}${url}`, body, {
        responseType: 'json',
        headers: {
          Authorization: queryToken ? tokenUrl(queryToken):`Bearer ${localStorage.getItem('user')}`,
          version: localStorage.getItem('version') || '',
        },
      });
      if (data.ok === false)
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: error.response.status,
        statusText: error.response.data?.message || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async put(url, body) {
    try {
      let { data } = await axios.put(`${process.env.REACT_APP_API_URL}${url}`, body, {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user')}`,
          version: localStorage.getItem('version') || '',
        },
      });
      if (data.ok === false)
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: error.response.status,
        statusText: error.response.data?.message || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async delete(url, body) {
    try {
      let { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}${url}`,
        {
          data: body,
        },
        {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user')}`,
            version: localStorage.getItem('version') || '',
          },
        }
      );
      if (data.ok === false)
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: error.response.status,
        statusText: error.response.data?.message || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
};
