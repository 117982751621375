import styled, { keyframes } from 'styled-components';
import Success from '../../../../../Assets/img/Actions/success.svg';

export const CheckboxStyles = styled.section`
  /* The switch - the box around the slider */
  z-index: 100;
  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f8fa;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 4px;
    border: 1px solid #cbd6e2;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -1px;
    bottom: -1px;
    border: 1px solid #cbd6e2;
    border-radius: 4px;
    background-color: white;
    transition: all .3s;
    background: url(${Success}) no-repeat fixed center white;
  }

  input:checked + .slider {
    background-color: #2ca4bd;
    
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {

    transform: translateX(26px);
    border: 1px solid #2ca4bd;
  }
`
export const ConfigureStyles = styled.section`
  padding: 2rem 0;
  h4 {
      font-size: 16px;
      font-weight: 600;
    }
  p {
    font-weight: 300;
    line-height: 24px;
  }
  a {
    color: var(--primary-hubspot-color);
    text-decoration: none;
    font-weight: 600;
  }
  & .review {
    & .item-left, & .item-right {
          padding: 6px 20px;
      }
    & .item-center {
      opacity: 0.6
    }
    }
  & .sync-direction {
    margin-bottom: 2rem;
    & .title-main {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    & .section-sync {
      margin: 16px 0;
      & .grid {
        display: grid;
        grid-template-columns: 1fr 75px 1fr;
        column-gap: 30px;
        & .item-left, & .item-right {
          border: 1px solid rgb(223, 227, 235);
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 20px;
          & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            & .image-app {
              max-width: 40px;
            }
            & .title-app {
              margin-left: 1.2rem;
            }
          }
        }
        & .item-center {
          & .hubspot.btn-secondary.light {
            padding: 8px;
          }
        }
      }

      & .card-sync {
        border: 1px solid rgb(223, 227, 235);
        margin-bottom: 2rem;
        & .header {
          border: 1px solid rgb(223, 227, 235);
          background-color: rgb(245, 248, 250);
          & .grid {
            & .item-left, & .item-right {
              border: none;
            }
          }
        }
        & .body-card {
          padding: 1rem;
          & .group-filter {
            padding-top: 1rem;
            display: flex;
            gap: 1rem;
            align-items: center;

          }

          & .list-success {
            display: flex;
            align-items: center;
            gap: 0.5rem ;
          }
          .radio-wrapper {
            padding-top: 0.5rem;
          }

          .radio-wrapper label {
            position: relative;
            cursor: pointer;
            line-height: 20px;
            font-size: 14px;
            padding: 3px 0;
          }

          .radio-wrapper .label {
            position: relative;
            display: block;
            float: left;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            border: 2px solid #c8ccd4;
            border-radius: 100%;
            -webkit-tap-highlight-color: transparent;
            margin-top: 1px;
          }
          .radio-wrapper .label:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: rgb(0, 164, 189);;
            transform: scale(0);
            transition: all 0.2s ease;
            opacity: 0.08;
            pointer-events: none;
          }
          .radio-wrapper:hover .label:after {
            transform: scale(3.6);
          }
          .radio-wrapper input[type="radio"]:checked + .label {
            border-color: rgb(0, 164, 189);;
          }
          .radio-wrapper input[type="radio"]:checked + .label:after {
            transform: scale(1);
            transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
            opacity: 1;
          }
          .radio-wrapper input[type="radio"] {
            display: none;
          }

          .radio-wrapper {
            .select {
              width: 50%;
            }
            input {
              padding: 0.7rem;
              border: 1px solid lightgray;
              border-radius: 6px;
            }
          }
          & h4 {
            margin: 0.5rem 0;
          }
        }
      }
    }
  }

  & .custom-mapping {
    margin-top: 2rem;
    & .section-custom {
      width: 100%;
      border: 1px solid lightgray;
      padding: 1rem;
      & .section-custom-inner {
        display: flex;
        justify-content: center;
        gap: 5rem;
        & .select {
          width: 300px;
          &.small {
            width: 90px;
            font-size: 20px;  
          }
        }
        & .actions {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }
`;

